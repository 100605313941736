import { ImageGrid } from './ImageGrid'
// import UploadForm from './UploadForm'
// import UploadImage_Pedro from './UploadImage_Pedro'
// import UseStorage_youtube from '../comps/UseStorage_youtube'

const Title = () => {
  return (
    <>
      <>
        <ImageGrid />
      </>
    </>
  )
}

export default Title
